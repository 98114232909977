var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isConfirm
    ? _c(
        "v-container",
        { attrs: { "fill-height": "", height: "100vh" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                column: "",
                "fill-height": "",
                wrap: "",
                height: "100vh"
              }
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "mx-auto pa-1",
                  staticStyle: { width: "90%", "max-width": "450px" }
                },
                [
                  _vm.agencyPhoneNumber
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "ma-0 pa-0" },
                            [
                              _c("TelButton", {
                                attrs: {
                                  agencyTel: _vm.agencyPhoneNumber,
                                  buttonClass: "float-right"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-card-title", { staticClass: "font-weight-bold pb-2" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("title.contractor.auth")) +
                        "\n      "
                    )
                  ]),
                  _c("v-row", { staticClass: "pl-4" }, [
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.sendAuthMessage) +
                          "\n        "
                      )
                    ])
                  ]),
                  _c(
                    "v-container",
                    [
                      _c(
                        "ValidationForms",
                        { ref: "forms" },
                        [
                          _c("v-row", { staticClass: "mb-2" }, [
                            _vm._v(_vm._s(_vm.$t("label.authCode")))
                          ]),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-auto",
                              staticStyle: {
                                width: "90%",
                                "max-width": "350px"
                              }
                            },
                            [
                              _c(
                                "ValidationForm",
                                { attrs: { rules: _vm.authRules } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: _vm.$t("label.authCode"),
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.onConvertToHalfWidth()
                                      },
                                      blur: function($event) {
                                        return _vm.onConvertToHalfWidth()
                                      },
                                      compositionend: function($event) {
                                        return _vm.onConvertToHalfWidth()
                                      }
                                    },
                                    model: {
                                      value: _vm.authCode,
                                      callback: function($$v) {
                                        _vm.authCode = $$v
                                      },
                                      expression: "authCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isError
                        ? [
                            _c(
                              "v-row",
                              {
                                staticClass: "mx-auto",
                                staticStyle: {
                                  width: "90%",
                                  "max-width": "350px"
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "font-weight-bold",
                                    staticStyle: { color: "red" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("error.selfCheckError")) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "my-2" },
                        [
                          _c("div", { staticClass: "text-center my-auto" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.continuationFlg,
                                  expression: "continuationFlg"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "continuationFlg",
                                value: "continuationFlg"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.continuationFlg)
                                  ? _vm._i(
                                      _vm.continuationFlg,
                                      "continuationFlg"
                                    ) > -1
                                  : _vm.continuationFlg
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.continuationFlg,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "continuationFlg",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.continuationFlg = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.continuationFlg = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.continuationFlg = $$c
                                  }
                                }
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "ml-1",
                                attrs: { for: "continuationFlg" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("checkbox.continuationFlg"))
                                )
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.splitMessageLine(
                              _vm.$t("description.continuationFlg")
                            ),
                            function(message, index) {
                              return _c(
                                "p",
                                {
                                  key: index,
                                  staticClass: "ml-2 my-0",
                                  staticStyle: { "font-size": "14px" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(message) +
                                      "\n          "
                                  )
                                ]
                              )
                            }
                          )
                        ],
                        2
                      )
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-2 justify-center" },
                    [
                      _c(
                        "v-col",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black mx-auto",
                              staticStyle: { "font-size": "20px" },
                              attrs: {
                                rounded: "",
                                align: "center",
                                width: "100%",
                                "max-width": "120px",
                                color: "back",
                                dark: ""
                              },
                              on: { click: _vm.backlAuth }
                            },
                            [_vm._v(_vm._s(_vm.$t("button.back")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black mx-auto",
                              staticStyle: { "font-size": "20px" },
                              attrs: {
                                rounded: "",
                                align: "center",
                                width: "100%",
                                "max-width": "120px",
                                color: "next",
                                dark: !(_vm.isConnecting || _vm.isAgency),
                                disabled: _vm.isConnecting || _vm.isAgency
                              },
                              on: { click: _vm.resendAuth }
                            },
                            [_vm._v(_vm._s(_vm.$t("button.resend")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black mx-auto",
                              staticStyle: { "font-size": "20px" },
                              attrs: {
                                rounded: "",
                                align: "center",
                                width: "100%",
                                "max-width": "120px",
                                color: "next",
                                dark: !_vm.isConnecting,
                                disabled: _vm.isConnecting
                              },
                              on: { click: _vm.onAuth }
                            },
                            [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isAgency
                    ? [
                        _c(
                          "v-card-title",
                          { staticClass: "font-weight-bold pb-2" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("label.contactInformation")) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._l(_vm.agencyRecords, function(agencyRecord) {
                          return _c(
                            "v-row",
                            { key: agencyRecord.key, staticClass: "mt-2" },
                            [
                              _c("v-col", { staticClass: "my-0 py-0" }, [
                                _c(
                                  "p",
                                  { staticClass: "font-weight-bold mt-1" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "header.agencyInfoErrorTable." +
                                              agencyRecord.key
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]),
                              _c("v-col", { staticClass: "my-0 py-0" }, [
                                _c("p", { staticClass: "mt-1" }, [
                                  _vm._v(_vm._s(agencyRecord.value))
                                ])
                              ])
                            ],
                            1
                          )
                        })
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c("Dialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showResndAuthCodeSuccessDialog,
                  title: null,
                  text: _vm.sendAuthCompleteMessage,
                  subText: null,
                  negativeButtonTitle: null,
                  positiveButtonTitle: _vm.$t("button.close"),
                  onClickNegativeButton: null,
                  onClickPositiveButton: _vm.closeResndAuthCodeSuccessDialog
                }
              }),
              _c("Dialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showResndAuthCodeFailureDialog,
                  title: null,
                  text: _vm.resndAuthCodeFailureMessage,
                  subText: null,
                  negativeButtonTitle: null,
                  positiveButtonTitle: _vm.$t("button.close"),
                  onClickNegativeButton: null,
                  onClickPositiveButton: _vm.closeResndAuthCodeFailureDialog
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }